
const files = [
	{
		collect_id: 'col230716',
		collect_introtext: '林媛媛 共识工具包 更新于20230716',
		file_path: '/files/20230716/',
		children: [
			{id: 1, file_name: '视疲劳诊疗专家共识（2014年）.pdf'},
			{id: 2, file_name: '中国干眼专家共识：治疗（2020年）.pdf'},
			{id: 3, file_name: '中国干眼专家共识：检查和诊断（2020年）.pdf'},
			{id: 4, file_name: '中国干眼专家共识：定义与分类篇（2020版）.pdf'},
			{id: 5, file_name: '我国翼状胬肉围手术期用药专家共识（2017年）.pdf'},
			{id: 6, file_name: '中国干眼专家共识：药物相关性干眼（2021年）.pdf'},
			{id: 7, file_name: '我国蠕形螨睑缘炎诊断和治疗专家共识（2018年）.pdf'},
			{id: 8, file_name: '我国过敏性结膜炎诊断和治疗专家共识（2018年）.pdf'},
			{id: 9, file_name: '中国干眼专家共识：眼手术相关性干眼（2021）.pdf'},
			{id: 10, file_name: '中国白内障围手术期干眼防治专家共识（2021）.pdf'},
			{id: 11, file_name: '中国干眼专家共识：生活方式相关性干眼2022年.pdf'},
			{id: 12, file_name: '我国睑板腺功能障碍诊断与治疗专家共识（2017年）.pdf'},
			{id: 13, file_name: '糖尿病相关眼病防治多学科中国专家共识（2021年版）.pdf'},
			{id: 14, file_name: '中国干眼专家共识：免疫性疾病相关性干眼（2021年）.pdf'},
			{id: 15, file_name: '中国角膜屈光手术围手术期干眼诊疗专家共识（2021）.pdf'},
			{id: 16, file_name: '中国抗青光眼药物相关眼表疾病诊疗专家共识（2022年）.pdf'},
		]
	},
	{
		collect_id: 'col230725',
		collect_introtext: '桐桐 客户临时放的演示视频',
		file_path: '/files/20230725/',
		children: [
			{id: 1, file_name: 'mishitaotuo1.mp4'},
			{id: 2, file_name: 'mishitaotuo2.mp4'},
			{id: 3, file_name: 'shipin4.mp4'},
		]
	},
	{
		collect_id: 'col230727',
		collect_introtext: '盛光 “世界肝炎日” 朋友圈视频',
		file_path: '/files/20230727/',
		children: [
			// {id: 1, file_name: '1.0727.mp4'},
			{id: 1, webUrl: 'https://aliyunoss.suncomu.com/assets/videos/1.0727.mp4'}
		]
	},
	{
		collect_id: 'col230801',
		collect_introtext: '林媛媛 视频 思然作用机制新版',
		file_path: '/files/20230801/',
		children: [
			{id: 1, file_name: '1.mp4'},
		]
	},
	{
		collect_id: 'col231017',
		collect_introtext: '汪钧 贝博萨文件',
		file_path: '/files/20231017/',
		children: [
			{id: 1, file_name: '贝博萨说明书解读幻灯-0307.pdf'},
			{id: 2, file_name: '奥加伊妥珠单抗治疗急性淋巴细胞白血病临床应用指导原则（2023年版）解读20230914PP-INO-CHN-0649.pdf'},
			{id: 3, file_name: '【PP-INO-CHN-0601】贝博萨治疗手册0721.pdf'},
		]
	},
	{
		collect_id: 'col240126',
		collect_introtext: '视频',
		file_path: '/files/20240126/',
		children: [
			{id: 1, webUrl: 'https://mp.weixin.qq.com/s?__biz=MzIwMTU3NzQxNA==&mid=2649902674&idx=1&sn=6eac01d73f97271eda396a60966044d7&chksm=8eed28fbb99aa1ed74d2537b7327fe8a06caab2e362f0293d50891960f96c4866354eb1a67e8#rd'},
		]
	},
	{
		collect_id: 'col240129',
		collect_introtext: '视频',
		file_path: '/files/20240129/', // 华天餐饮做菜视频
		children: [
			{id: 1, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/1.mp4'},
			{id: 2, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/2.mp4'},
			{id: 3, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/3.mp4'},
			{id: 4, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/4.mp4'},
			{id: 5, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/5.mp4'},
			{id: 6, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/6.mp4'},
		]
	},
	{
		collect_id: 'col240131',
		collect_introtext: '视频',
		file_path: '/files/20240131/',
		children: [
			{id: 1, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20240131/1.mp4'},
			{id: 2, webUrl: 'https://mp.weixin.qq.com/s?__biz=MzU2NzgyNDUwMg==&tempkey=MTI1NF9rYklhR0dNeEpuSHJYZ0pvSVVZWXltZjJPSHlxdVdOV2UycVBtbGFFbDMxNXo5YjNCdC1XdEpaUlRiTjFuU3Rvall0VzlQVk1uQlowakZnQ00xYXBHTkI3VlUyTHByeXlRSzVkQ1F0YThuSXI3R0dpb3lQTmtkUV9sdTIxX0JjNUR2dXRVdWZzT081QWZETm5PNDZlcTV3MTI2QW80MU01UERqb0hBfn4%3D&chksm=7c95d7cd4be25edbeccae3e0a640e3af4649a90723d02fa544c367d710120f6f8ebde6df30dd&scene=0&xtrack=1&previewkey=F6xuUt0VA1TxXZG2Br9ZCMwqSljwj2bfCUaCyDofEow%253D#wechat_redirect'},
		]
	},
	{
		collect_id: 'col240306',
		collect_introtext: '汪钧 地图药店检索程序',
		file_path: '',
		children: [
			{id: 1, webUrl: ''},
		]
	},
	{
		collect_id: 'col240430',
		collect_introtext: '林媛媛-爱尔康-眨眼操',
		file_path: '',
		children: [
			{id: 1, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/aierkang/videos/眨眼操_0430.mp4'},
		]
	},
	{
		collect_id: 'col240709',
		collect_introtext: '林媛媛-爱尔康-干眼自测小视频',
		file_path: '',
		children: [
			{id: 1, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/aierkang/videos/干眼自测小视频.mp4'},
		]
	},
	{
		collect_id: 'col250112',
		collect_introtext: '华天视频',
		file_path: '',
		children: [
			{id: 1, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20250113/1%E5%AE%AB%E4%BF%9D%E9%B8%A1%E4%B8%81.mp4'},
			{id: 2, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20250113/2%E7%83%A7%E5%85%A8%E7%B4%A0.mp4'},
			{id: 3, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20250113/3%E7%88%86%E7%82%92%E9%B1%BF%E9%B1%BC%E8%8A%B1.mp4'},
			{id: 4, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20250113/4%E6%9D%BE%E4%BB%81%E7%8E%89%E7%B1%B3.mp4'},
			{id: 5, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20250113/5%E7%B2%89%E8%92%B8%E8%82%89.mp4'},
			{id: 6, webUrl: 'https://suncforpfiz.oss-cn-beijing.aliyuncs.com/huatian/c20250113/6%E8%B1%89%E6%B1%81%E8%92%B8%E6%8E%92%E9%AA%A8.mp4'},
		]
	},
]

export const getFileUrl = (collect_id, id) => {

	const collectList = files.filter(item => item.collect_id == collect_id)
	if (collectList.length < 1) {
		return ''
	}

	const { file_path, children } = collectList[0]
	const fileList = children.filter(item => item.id == id)
	if (fileList.length < 1) {
		return ''
	}
	const {file_name, webUrl } = fileList[0]

	return webUrl ? webUrl : `${file_path}${file_name}`
}